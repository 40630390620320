import PerfectScrollbar from 'perfect-scrollbar';

export default class Header {
    constructor() {
        this.window_width = $(window).width();
    }

    __init() {
        this.sticky();
        this.hamburger();
        this.megaMenu();
    }

    /**
     * Sticky header
     */
    sticky() {
        let $element = $('[data-plugin="sticky"]');

        let $html = $('html');
        let position = $element.height();

        let fn = () => {
            $html.addClass('sticky');
            $element.addClass('sticky');
        };

        if (window.pageYOffset >= position) {
            fn();
        }

        $(window).scroll(function () {
            if ($(document).scrollTop() > position) {
                fn();
            } else {
                $html.removeClass('sticky');
                $element.removeClass('sticky');
            }
        });
    }

    /**
     * Hamburger Menu
     */
    hamburger() {
        let $button_close = $('[data-toggle="collapse"].hamburger');
        let w_window = this.window_width;

        if ($button_close.length > 0) {
            $button_close.on('click', function () {
                let $this = $(this);
                let $body = $('body');

                if (!$this.hasClass('is-active') && $this.attr('aria-expanded') === 'false') {
                    $this.addClass('is-active');

                    if (w_window < 992) {
                        $body.addClass('menu-open');
                    }
                } else {
                    $this.removeClass('is-active');
                    $body.removeClass('menu-open');
                }
            });
        }
    }

    /**
     * Mega menu
     */
    megaMenu() {
        let w_window = this.window_width;

        let toggleDropdownSubmenu = function (e) {
            // Disable "hover" mouse
            if (w_window < 1200 && e.type === 'mouseenter') {
                return;
            }

            const $this = $(this);
            let $nav_item = $this.parent();

            let $menu_container = $('[data-menu="mega"] [data-menu-level="0"].show');

            if (w_window >= 1200 && e.type === 'click' && !$menu_container.find(e.target).length > 0) {
                $('> .btn', $menu_container).trigger('click');
                return;
            }

            e.stopPropagation();

            let $submenu_container = $this.closest('[data-menu-level="0"]').find('.submenu-container > .submenu-container--elements');
            let $menu = $nav_item.find('> .dropdown-menu')
            let level = $menu.data('menu-level');

            if (level === undefined) {
                level = $nav_item.data('menu-level') + 1;
            }

            let expanded = $('> .btn', $nav_item).attr('aria-expanded') === 'false';

            // Only for the first level
            if ($menu.hasClass('submenu-container')) {
                $menu.addClass('show');
            }

            // Remove all levels
            let removeSubLevels = function ($submenu_container, level) {
                let remove_submenu = $submenu_container.find('> [data-menu-level="' + level + '"]').length > 0;

                while (remove_submenu) {
                    let $pather_elements = $submenu_container.find('> [data-menu-level="' + (level - 1) + '"]');

                    if ($pather_elements.length > 0) {
                        $pather_elements.find('.active').removeClass('active');
                        $pather_elements.find('.btn').attr('aria-expanded', false);
                    }

                    $submenu_container.find('> [data-menu-level="' + level + '"]').remove();

                    // Levels in same
                    remove_submenu = $submenu_container.find('> [data-menu-level="' + ++level + '"]').length > 0;
                }
            };

            if (w_window >= 992) {
                removeSubLevels($submenu_container, level);
            }

            $(window).on('resize', function () {
                removeSubLevels($submenu_container, 2);
            })

            if ($menu.length > 0) {
                $nav_item.addClass('active show');
                $nav_item.find('> .btn').attr('aria-expanded', expanded);

                if (w_window < 992) {
                    $menu.toggleClass('show');
                    // $('> .nav-item', $menu).toggleClass('fadeIn animated'); // Toggle animation
                }

                if (expanded && w_window >= 992) {
                    $menu = $menu.clone().removeClass('dropdown-menu')
                    $submenu_container.append($menu);
                }
            }

            $this.parents('li[data-menu-level="0"]').on('hidden.bs.dropdown', function (e) {
                let $this = $(this);

                $('.show', $this).removeClass('show');
                $('.active', $this).removeClass('active');
                $('[aria-expanded="true"]', $this).attr('aria-expanded', false);

                // Remove sublevels from Level 2
                removeSubLevels($submenu_container, 2, true);
            });
        };

        let dropdownItemsInLoad = function ($item) {
            if ($(window).width() < 1200) {
                return;
            }

            if ($item === undefined) {
                $item = $('> .current-menu-item', '[data-menu="mega"] li[data-menu-level="0"] > .dropdown-menu > .submenu-container--elements > .navbar-submenu');
            }

            let $container = $item.closest('[data-menu-level="0"]').find('.submenu-container > .submenu-container--elements');
            let $menu = $item.find('> .dropdown-menu');

            if ($menu.length > 0) {
                $item.addClass('active show');
                $item.find('> .btn').attr('aria-expanded', 'true');

                $menu = $menu.clone().removeClass('dropdown-menu')
                $container.append($menu);

                $item = $('> .current-menu-item', $menu);

                if ($item.length > 0) {
                    dropdownItemsInLoad($item);
                }
            }
        }

        let toggleDropdown = function (e) {
            // Remove all submenus in other containers
            const $dropdown = $(e.target).closest('.dropdown'),
                $dropdown_menu = $('> .dropdown-menu', $dropdown);

            // remove title
            if (w_window < 992) {
                $('> .dropdown-menu', $dropdown).removeClass('fadeIn fadeOut animated');

                return;
            }

            // $dropdown.find('.active').removeClass('active');

            const shouldOpen = $dropdown.is(':hover') && !$dropdown.hasClass('show');

            // Add animations only in desktop
            if (shouldOpen) {
                $('> .dropdown-menu', $dropdown).removeClass('fadeOut animated').addClass('fadeIn animated');
                $dropdown.find('.nav-item').addClass('fadeInDown animated');
            } else {
                $('> .dropdown-menu', $dropdown).removeClass('fadeIn animated').addClass('fadeOut animated');
                $dropdown.find('.nav-item').removeClass('fadeInDown animated')
            }
        };

        /**
         * Multi Level dropdowns
         */
        let $body = $('body');

        if ($(window).width() >= 1200) {
            $body.on('click', toggleDropdownSubmenu);
        }

        // Click on submenu container
        $body.on('click', '[data-menu="mega"] .submenu-container [data-toggle="dropdown"]', toggleDropdownSubmenu);
        $body.on('mouseenter', '.submenu-container [data-toggle="dropdown"][aria-expanded="false"], .submenu-container .nav-link', toggleDropdownSubmenu);

        // First Level
        // 'mouseenter mouseleave click'
        $('[data-menu-level="0"] > .btn').on('click', function (e) {
            toggleDropdown(e);
            dropdownItemsInLoad();
        });


        new PerfectScrollbar('.submenu-container--elements', {
            wheelSpeed: 1,
            wheelPropagation: true,
            minScrollbarLength: 20,
            swipeEasing: true
        });
    }
}
