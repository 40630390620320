export default class SeeMore {
  __init() {
    this.simple();
  }

  simple() {
    const $container = $('[data-plugin="see-more"]');
    let $grid = $('[data-container="cards"]', $container);

    const equalizeHeights = function () {
      if (window.innerWidth >= 768) {
        let maxRowHeight = 0;
        let currentRowTop = 0;
        let itemsInRow = [];

        $grid.find('.uq-blog-post').each(function () {
          let $item = $(this);
          let itemTop = $item.position().top;

          if (itemTop !== currentRowTop) {
            // We have encountered a new row. Set the heights of the previous row.
            if (itemsInRow.length) {
              $(itemsInRow).height(maxRowHeight);
              itemsInRow = [];
              maxRowHeight = 0;
            }
            currentRowTop = itemTop;
          }

          itemsInRow.push(this);
          maxRowHeight = Math.max(maxRowHeight, $item.outerHeight());
        });

        // Equalize heights for the last row
        if (itemsInRow.length) {
          $(itemsInRow).height(maxRowHeight);
        }
      }
    };

    const applyGrid = function () {
      $grid.isotope({
        itemSelector: '.uq-blog-post',
        layoutMode: 'fitRows',
        percentPosition: true,
      });

      equalizeHeights();
    };

    $(window).on('resize', function () {
      $grid.isotope('layout');
      setTimeout(equalizeHeights, 300);
    });

    $grid.imagesLoaded().always(function () {
      applyGrid();
    });

    $grid.on('layoutComplete', function (event, laidOutItems) {
      equalizeHeights();
    });

    $container.on('filter.applied filter.load_more', function (e) {
      // Cancel previous request
      if (window.source !== undefined) {
        // Cancel previous request
        window.source.cancel();
      }

      window.source = window.axios.CancelToken.source();

      let $this = $(this), href, params = {};

      href = $('[data-container="pagination"] .btn', $this).data('href');

      window.axios.get(href, { params: params, cancelToken: window.source.token })
        .then(function (response) {
          let $response = $(response.data);

          // Remove currently
          if (e.namespace === 'applied') {
            let $items = $grid.find('.uq-blog-post');
            $grid.isotope('remove', $items);
          }

          // Update pagination
          let $new_pagination = $('[data-container="pagination"]', $response).html();
          $new_pagination = $new_pagination ? $new_pagination : '';

          $('[data-container="pagination"]', $container).html($new_pagination);

          // Get find .uq-blog-post in response
          let $columns = $response.find('[data-plugin="see-more"] .uq-blog-post', $response);

          // Append elements to masonry
          $columns.each(function () {
            let $this = $(this);

            $grid = $grid.append($this)
              .isotope('appended', $this);
          });

          applyGrid();

          let $loading = $('.loading', $container);

          $loading.find('.loader').remove();
          $loading.removeClass('loading');

          setTimeout(equalizeHeights, 500);
        })
        .catch(() => {
          let $loading = $('.loading', $container);
          //
          $loading.find('.loader').remove();
          $loading.removeClass('loading');
        });
    });

    $('body').on('click', '[data-plugin="see-more"] [data-container="pagination"] .btn', function (e) {
      e.preventDefault();

      $container.trigger('filter.load_more');

      let $this = $(this);
      $this.addClass('loading');
      $this.prepend('<div class="loader"></div>');
    });

    applyGrid($grid);
  }
}
