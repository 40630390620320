export default class Footer {
    __init() {
        this.backToTop();
    }

    /**
     * Footer back to top
     */
    backToTop() {
        let $element = $('[data-plugin="back-to-top"]');

        $element.on('click', function (e) {
            e.preventDefault();

            $('html,body').animate({
                scrollTop: 0
            }, 1200);
        });
    }
}
