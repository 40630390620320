window.GMaps = require('gmaps');

export default class Map {
    __init() {
        this.offices();
    }

    offices() {
        let loadMap = function ($this) {
            let icon = $this.data('icon');

            $this = $this.find('[data-map="false"]');

            if ($this.length === 0) {
                return;
            }

            let lat = $this.data('lat');
            let lng = $this.data('lng');

            let $map = GMaps({
                div: '#' + $this.attr('id'),
                lat: lat,
                lng: lng,
                disableDefaultUI: true,
                zoom: 16,
            });

            $map.setCenter(lat, lng);
            $map.addMarker({
                lat: lat,
                lng: lng,
                icon: icon,
            });

            $this.attr('data-map', 'true')
        };

        $('[data-map="container"]').each(function() {
            loadMap($(this));
        });
    }
}