import './bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import Slider from './components/Slider';
import Map from './components/Map';
import SeeMore from './components/SeeMore';
import Masonry from 'masonry-layout';
import Isotope from 'isotope-layout';
import imagesLoaded from 'imagesloaded';

window.jQueryBridget = require('jquery-bridget');
window.axios = require('axios');

imagesLoaded.makeJQueryPlugin($);
jQueryBridget('masonry', Masonry, $);
jQueryBridget('isotope', Isotope, $);

$(document).ready(function () {
  const $header = new Header();
  $header.__init();

  const $footer = new Footer();
  $footer.__init();

  const $slider = new Slider();
  $slider.__init();

  const $map = new Map();
  $map.__init();

  const $seeMore = new SeeMore();
  $seeMore.__init();

  $('.countries-menu-container').masonry({
    itemSelector: '.continent',
  });

  $('.uq-practice-area-menu').on('click', '[data-toggle="collapse"]', function (e) {
    let $this = $(this);

    let id = $this.data('target');
    let open_id = '#' + $('.uq-submenu-column-area.show').attr('id');

    if (id === open_id) {
      e.stopPropagation();
    }
  });

  $('.owl-carousel').each(function () {
    $(this).find('.owl-dot').each(function (index) {
      $(this).attr('aria-label', index + 1);
    });
  });
});
