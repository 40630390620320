export default class Slider {
    __init() {
        this.simple();
    }

    simple() {
        let $sliders = $('[data-slider]');

        $sliders.each(function (i) {
            let $slider = $($sliders[i]);

            let params = {
                loop: $slider.data('loop') === undefined || $slider.data('loop') === 1,
                margin: 0,
                mouseDrag: false,
                dots: $slider.data('dots') === 1 || true,
                nav: $slider.data('nav') === 1,
                navText: [
                    '<i class="dt-icon icon-chevron-left"></i>', '<i class="dt-icon icon-chevron-right"></i>'
                ],
                responsive: {
                    0: {
                        autoHeight: true
                    },
                    992: {
                        autoHeight: false
                    }
                },
            };

            let numItems = $slider.data('items');

            if (numItems !== undefined) {
                for (let i in numItems) {
                    let num = numItems[i];

                    let value = params.responsive[i];
                    value = value !== undefined ? {...value, ...num} : num

                    params.responsive[i] = value;
                }
            }

            let owl = $('.owl-carousel', $slider).owlCarousel(params);

            let dotsContainer = $slider.data('nav') === 'custom';

            if (dotsContainer) {
                $('[data-total="true"]', $slider).text($('.owl-dots .owl-dot', $slider).length);

                $('[data-prev="true"]', $slider).on('click', function () {
                    owl.trigger('prev.owl.carousel');
                });

                $('[data-next="true"]', $slider).on('click', function () {
                    owl.trigger('next.owl.carousel');
                });

                owl.on('changed.owl.carousel resized.owl.carousel initialized.owl.carousel initialize.owl.carousel', function (e) {
                    let current_page = e.page.index + 1;

                    $('[data-current="true"]', $slider).text(current_page);
                    $('[data-total="true"]', $slider).text(e.page.count);

                });
            }
        });
    }
}